
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../../.sourceflow/metadata.json";
import { NextSeo } from 'next-seo';
import Script from 'next/script'

export default function SeoHead({
    site_name = "Global Recruitment | Xcede Group",
    page_name = "Global Recruitment | Xcede Group",
    description = "We are the Xcede Group, a global recruitment business working across every aspect of STEM innovation. Explore our brands.",
    canonical_link = "https://www.xcedegroup.com",
    og_image = "https://cdn.sourceflow.co.uk/lnbos2zk07150v2300f4ztml76su",
    og_img_alt = "Global Recruitment | Xcede Group",
    robots=""
}) {
    return (
        <>
            <SourceFlowHead metaObject={metaObject}>
                <title>{page_name}</title>
                <meta name="title" content={page_name} />
                <meta name="description" content={description} />
                <meta name="robots" content={robots} />
                <link rel="canonical" href={canonical_link} key="canonical" />
                <meta name="twitter:title" content={page_name} />
                <meta name="twitter:description" content={description} />
            </SourceFlowHead>

            <NextSeo
                openGraph={{
                    url: canonical_link,
                    title: page_name,
                    site_name: site_name,
                    description: description,
                    images: [
                        {
                            url: og_image,
                            width: 1200,
                            height: 600,
                            alt: og_img_alt,
                            type: 'image/jpg',
                        }
                    ],
                }}
            />
        </>
    )
}
